import React from "react"
import tw from "twin.macro"
import SEO from "../components/SEO"
import { Layout } from "../components/Layout"

const Container = tw.div`px-4`

export default function AboutPage() {
    return (
        <Layout>
            <SEO
                title="About Long Rock Labs"
                description="Find out more about Long Rock Labs founders."
                meta={[]}
            />
            <Container>
            </Container>
        </Layout>
    )
}
